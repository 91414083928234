/*
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-27 03:22:45
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-07-10 07:41:37
 * @FilePath: /yingcai-culture-h5/src/api/home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ajax from "./require";

export const getPosterList = () => {
  return ajax.get("/mobile/poster", {});
};

export const getColumnsList = () => {
  return ajax.get("/mobile/columns", {});
};

export const getArticleGroupList = (params = {}) => {
  return ajax.get("/mobile/articleGroup", params);
};

export const getArticleDetail = (params) => {
  return ajax.get("/mobile/article", params);
};

export const checkCertificateNo = (params) => {
  return ajax.get("/mobile/checkCertificateNo", params);
};
export const checkCertificateMatchNo = (params) => {
  return ajax.get("/mobile/checkCertificateMatch", params);
};
export const getNationalityList = (params) => {
  return ajax.get("/nationality/list", params);
};
export const getLevelList = (params) => {
  return ajax.get("/level/list", params);
};
export const getObjectIdList = (params) => {
  return ajax.get("/object/list", params);
};
export const createCertificateStudentApplication = (params) => {
  return ajax.post("/certificateStudentApplication", params);
};
export const getPinyin = (params) => {
  return ajax.get("/common/getPinyin", params);
};
export const getWxShareConfig = (params) => {
  return ajax.get("/wechat/getWxJsapiSignature", params);
};

export const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("groupId", "a1aff2af5c6d2abd0251538e88483e30");
  return ajax.post("/file/upload", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
