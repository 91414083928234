<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import wxUtils from "./utils/wxUtils";

export default {
  mounted() {
    setTimeout(() => {
      wxUtils.share({
        title: "中華文化藝術交流協會",
        desc: "中華文化藝術交流協會",
        link: this.$store.state.initLink,
        imgUrl:
          "https://qgyc-system.oss-cn-hangzhou.aliyuncs.com/chinaculture/favicon.jpeg",
      });
    }, 500);
  },
};
</script>

<style lang="less"></style>
